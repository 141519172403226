// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file auth/v1/type.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { UUID } from "../../shared/type_pb.js";

/**
 * @generated from enum auth.v1.Provider
 */
export enum Provider {
  /**
   * @generated from enum value: PROVIDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PROVIDER_GOOGLE = 1;
   */
  GOOGLE = 1,

  /**
   * @generated from enum value: PROVIDER_APPLE = 2;
   */
  APPLE = 2,

  /**
   * @generated from enum value: PROVIDER_TWITTER = 3;
   */
  TWITTER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Provider)
proto3.util.setEnumType(Provider, "auth.v1.Provider", [
  { no: 0, name: "PROVIDER_UNSPECIFIED" },
  { no: 1, name: "PROVIDER_GOOGLE" },
  { no: 2, name: "PROVIDER_APPLE" },
  { no: 3, name: "PROVIDER_TWITTER" },
]);

/**
 * @generated from message auth.v1.UserAuthentication
 */
export class UserAuthentication extends Message<UserAuthentication> {
  /**
   * @generated from field: auth.v1.Provider provider = 1;
   */
  provider = Provider.UNSPECIFIED;

  /**
   * @generated from field: string social_id = 2;
   */
  socialId = "";

  constructor(data?: PartialMessage<UserAuthentication>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "auth.v1.UserAuthentication";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "provider", kind: "enum", T: proto3.getEnumType(Provider) },
    { no: 2, name: "social_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserAuthentication {
    return new UserAuthentication().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserAuthentication {
    return new UserAuthentication().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserAuthentication {
    return new UserAuthentication().fromJsonString(jsonString, options);
  }

  static equals(a: UserAuthentication | PlainMessage<UserAuthentication> | undefined, b: UserAuthentication | PlainMessage<UserAuthentication> | undefined): boolean {
    return proto3.util.equals(UserAuthentication, a, b);
  }
}

/**
 * @generated from message auth.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: shared.UUID id = 1;
   */
  id?: UUID;

  /**
   * @generated from field: repeated auth.v1.UserAuthentication authentications = 2;
   */
  authentications: UserAuthentication[] = [];

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "auth.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: UUID },
    { no: 2, name: "authentications", kind: "message", T: UserAuthentication, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

